<template>
    <div>
        <loading-full-screen v-model="isUpdatingPersonalInfo" loadingText="Updating your details ..."/>
        
        <width-container>
            <MDBRow tag="form" class="g-3 needs-validation" novalidate @submit.prevent="checkForm">
                <MDBCol md="12">
                    
                    <div class="d-flex align-items-center justify-content-between page-title-and-buttons-cont">
                        <page-header class="page-title mb-0">Profile</page-header>
                        <div class="edit-save-button-cont text-right">
                            <button-bento v-if="isEditing" color="plain-transparent" @click="cancelChangesHandler" size="sm">
                                Cancel
                            </button-bento>
                            <button-bento v-if="!isEditing" color="dark" @click="editHandler" size="sm">
                                <img src="@/assets/v2/icons/edit/edit-pencil-solid-white.svg" class="me-2"/> <span>Edit Details</span>
                            </button-bento>
                            <button-bento v-if="isEditing && areChanges" color="dark" size="sm" type="submit">
                                <img src="@/assets/v2/icons/edit/save-white.svg" class="me-2 save-white-icon"/> <span>Save Changes</span>
                            </button-bento>
                        </div>
                    </div>
                    
                    <div v-if="incompleteAlert">
                        <bento-alert color="danger" class="mb-2" static v-model="incompleteAlert" v-if="incompleteAlert">Please complete all required fields below with valid entries.</bento-alert>
                    </div>
                    
                    <div class="my-4">
                        <card-general class="col-12 d-flex align-items-stretch pb-3">
                            <div class="row top-content-row">
                                
                                <div class="name-and-title-col d-flex">
                                    <div class="name-and-title-card flex-grow-1 my-0">
                                        <div>
                                            <h3 class="mb-1">{{ fullName }}</h3>
                                            <h3 v-if="preferredName">({{ preferredName }})</h3>
                                            <h4 class="job-title mt-3 mb-1" v-if="jobTitle">{{ jobTitle }}</h4>
                                            
                                            <div v-if="!isEditing && !selfDescription" class="cursor-pointer self-description font-weight-500" @click="editHandler">
                                                <img class="edit-pencil" src="@/assets/v2/icons/edit/edit-pencil-solid-green.svg" alt="Edit"/> Include a sentence about yourself ...
                                            </div>
                                            
                                            <div v-if="!isEditing" class="self-description font-weight-500">{{ selfDescription }}</div>
                                            
                                            <profile-info-card-text-area-edit
                                                class="mt-3" v-if="isEditing" v-model="selfDescription" :input-value="selfDescription" label="Sentence about yourself" :full-width="true"
                                                :max-length-value="400" :is-required="false"
                                                invalid-feedback="Please provide a sentence about yourself"
                                            />
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="profile-pic-image-col bento-border-radius">
                                    <div>
                                        <div
                                            class="p-0 position-relative my-0 flex-grow-1 profile-pic-uploader-cont bento-border-radius cursor-pointer"
                                            :style="`background-image: url(${profilePic})`"
                                            @click="showFileUploader = true"
                                        >
                                            <MDBFileUpload
                                                v-if="showFileUploader"
                                                ref="fileUploadRef"
                                                @change="fileUploadHandler" disabledRemoveBtn
                                                defaultMsg="Click or drag to update image. Square works best." previewMsg="Click to update image. Square works best."
                                                accept="image/*" :maxFileSize="5" :maxFilesQuantity="1"
                                                class="text-center w-100"
                                            />
                                        </div>
                                    </div>
                                    <div class="mt-2 d-flex justify-content-end align-items-center">
                                        <div v-if="showFileUploader" @click="cancelAndClearFileUploadHandler" class="mx-2 fw-500 cursor-pointer">Cancel</div>
                                        <button-bento class="ms-3" button-color="primary" v-if="isProfilePicChanged" size="sm" @click="updatePersonalInfo">Save image</button-bento>
                                    </div>
                                </div>
                            </div>
                        </card-general>
                    </div>

                    <card-general class="mt-0">
                        <section-header>Contact Details</section-header>
                        <div>
                            <div class="row g-3">
                                <profile-info-card v-if="!isEditing" title="Cell Number" :value="cellNumber"/>
                                <profile-info-card-input-edit
                                    v-else v-model="cellNumber" :input-value="cellNumber" label="Cell Phone Number"
                                    invalid-feedback="Please provide your phone number"
                                />
                                
                                <profile-info-card v-if="!isEditing" title="Preferred Name" :value="preferredName"/>
                                <profile-info-card-input-edit v-else v-model="preferredName" :input-value="preferredName" label="Preferred Name" :is-required="false"/>
                                
                                <profile-info-card title="Email" :value="email" :editable="false"/>
                                <profile-info-card title="ID Number" :value="idNumber" :editable="false"/>
                                
                                <profile-info-card title="Emergency Contact" :value="joinedOneLineEmergencyContact" :full-width="true"/>
                                <div v-if="isEditing" class="row g-3 mt-1">
                                    <profile-info-card-input-edit
                                        v-model="ecName" :input-value="ecName" label="Emergency Contact Name" :full-width="true"
                                        invalid-feedback="Please provide an emergency contact"
                                    />
                                    <profile-info-card-input-edit
                                        v-model="ecRelationship" :input-value="ecRelationship" label="Emergency Contact Relationship"
                                        invalid-feedback="Please provide emergency contact relationship"
                                    />
                                    <profile-info-card-input-edit
                                        v-model="ecCellNumber" :input-value="ecCellNumber" label="Emergency Contact Cell Number"
                                        invalid-feedback="Please provide emergency contact cell phone number"
                                    />
                                </div>
                            </div>
                        </div>
                    </card-general>
                    
                    <card-general>
                        <section-header>Home Address</section-header>
                        
                        <profile-info-card title="Address" :value="joinedOneLineAddress" :full-width="true"/>
                        
                        <div class="row g-3 mt-1" v-if="isEditing">
                            <profile-info-card-input-edit v-model="unitNumber" :input-value="unitNumber" label="Unit Number" :is-required="false"/>
                            <profile-info-card-input-edit v-model="complex" :input-value="complex" label="Complex" :is-required="false"/>
                            <profile-info-card-input-edit v-model="streetNumber" :input-value="streetNumber" label="Street Number" invalid-feedback="Please provide a street number"/>
                            <profile-info-card-input-edit v-model="street" :input-value="street" label="Street" invalid-feedback="Please provide a street name"/>
                            <profile-info-card-input-edit v-model="suburb" :input-value="suburb" label="Suburb" invalid-feedback="Please provide a suburb"/>
                            <profile-info-card-input-edit v-model="city" :input-value="city" label="City" invalid-feedback="Please provide a city"/>
                            <profile-info-card-input-edit v-model="postalCode" :input-value="postalCode" label="Postal Code" invalid-feedback="Please provide a postal code"/>
                            <profile-info-card-input-edit v-model="country" :input-value="country" label="Country" invalid-feedback="Please provide a country"/>
                        </div>
                    </card-general>
                    
                    <card-general v-if="false">
                        <section-header>Additional Info</section-header>
                        <div>
                            <div class="row g-3">
                                <profile-info-card v-if="!isEditing" title="Title" :value="title"/>
                                <profile-info-card-input-edit v-else v-model="title" :input-value="title" label="Title" :is-required="false"/>
                                
                                <profile-info-card v-if="!isEditing" title="Gender" :value="gender"/>
                                <profile-info-card-input-edit v-else v-model="gender" :input-value="gender" label="Gender" :is-required="false"/>
                                
                                <profile-info-card v-if="!isEditing" title="Marital Status" :value="maritalStatus"/>
                                <profile-info-card-input-edit v-else v-model="maritalStatus" :input-value="maritalStatus" label="Preferred Name" :is-required="false"/>
                            </div>
                        </div>
                    </card-general>
                    
                    <div class="d-flex align-items-center justify-content-end mb-1 mt-n3">
                        <div class="edit-save-button-cont">
                            <button-bento v-if="isEditing" color="plain-transparent" @click="cancelChangesHandler" size="sm">
                                Cancel
                            </button-bento>
                            <button-bento v-if="!isEditing" color="dark" @click="editHandler" size="sm">
                                <img src="@/assets/v2/icons/edit/edit-pencil-solid-white.svg" class="me-2"/> <span>Edit Details</span>
                            </button-bento>
                            <button-bento v-if="isEditing && areChanges" color="dark" size="sm" type="submit">
                                <img src="@/assets/v2/icons/edit/save-white.svg" class="me-2 save-white-icon"/> <span>Save Changes</span>
                            </button-bento>
                        </div>
                    </div>
                    
                    <div class="mt-3">
                        <bento-alert color="info" class="mb-2" static dismiss v-model="viewableNotice" v-if="viewableNotice">Emergency contact and your home address is kept private from other employees. Only your HR admin can view these details.</bento-alert>
                    </div>
                
                </MDBCol>
            </MDBRow>

            <!--Your Approvers-->
            <div class="mt-5" v-if="isEnabledLeave || isEnabledReimbursements">
                <page-header class="page-title mb-0">Approvers</page-header>
                
                <card-general v-if="isEnabledLeave">
                    <section-header>Leave Approver<span v-if="employeeLeaveApprovers.length > 1">s</span></section-header>
                    <div v-if="isValidArray(employeeLeaveApprovers)" class="mt-n3">
                        <template v-for="employee in employeeLeaveApprovers" :key="employee.id">
                            <employee-info-block :employee="employee" class="mt-3"/>
                        </template>
                    </div>
                    <div v-else>
                        <no-approver-notice/>
                    </div>
                </card-general>
                
                <card-general v-if="isEnabledReimbursements">
                    <section-header>Reimbursement Approver<span v-if="employeeReimbursementApprovers.length > 1">s</span></section-header>
                    <div v-if="isValidArray(employeeReimbursementApprovers)" class="mt-n3">
                        <template v-for="employee in employeeReimbursementApprovers" :key="employee.id">
                            <employee-info-block :employee="employee" class="mt-3"/>
                        </template>
                    </div>
                    <div v-else>
                        <no-approver-notice/>
                    </div>
                </card-general>
            </div>
            
            
            <!--Team - your approvees-->
            <div class="mt-5" v-if="isValidArray(leaveApproveeList) || isValidArray(reimbursementApproveeList)">
                <page-header class="page-title mb-0">Team</page-header>
                
                <card-general v-if="isEnabledLeave && isValidArray(leaveApproveeList)">
                    <section-header>Leave Approvees</section-header>
                    <p class="mt-n3">You are a leave approver for the following team members:</p>
                    <template v-for="employee in leaveApproveeList" :key="employee.id">
                        <employee-info-block :employee="employee" class="mt-3"/>
                    </template>
                </card-general>
                
                <card-general v-if="isEnabledReimbursements && isValidArray(reimbursementApproveeList)">
                    <section-header>Reimbursement Approvees</section-header>
                    <p class="mt-n3">You are a reimbursement approver for the following team members:</p>
                    <template v-for="employee in reimbursementApproveeList" :key="employee.id">
                        <employee-info-block :employee="employee" class="mt-3"/>
                    </template>
                </card-general>
            </div>
            
            <footer-contact-help-card/>
        </width-container>
    </div>
</template>
<script setup>
    import {MDBFileUpload} from "mdb-vue-file-upload"
    import {MDBRow, MDBCol} from "mdb-vue-ui-kit"
    import WidthContainer from "@/components/UI/v2/containers-cards-headers/WidthContainer.vue"
    import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue"
    import PageHeader from "@/components/UI/v2/containers-cards-headers/PageHeader.vue"
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento.vue"
    import {useStore} from "vuex"
    import {ref, computed} from 'vue';
    import SectionHeader from "@/components/UI/v2/containers-cards-headers/SectionHeader.vue"
    import LoadingFullScreen from "@/components/UI/v2/loading/LoadingFullScreen.vue"
    import ProfileInfoCard from "@/components/employee-profile-directory/ui-components/ProfileInfoCard.vue"
    import ProfileInfoCardInputEdit from "@/components/employee-profile-directory/ui-components/ProfileInfoCardInputEdit.vue"
    import ProfileInfoCardTextAreaEdit from "@/components/employee-profile-directory/ui-components/ProfileInfoCardTextAreaEdit.vue"
    import {isValidArray} from "@/hooks/general/type-and-value-checks"
    import BentoAlert from "@/components/UI/v2/alerts/BentoAlert.vue"
    import {isRequiredValidate} from "@/hooks/general/form-validators"
    import FooterContactHelpCard from "@/components/layouts/footer/FooterContactHelpCard.vue"
    import userPlaceholder from "@/assets/v2/placeholders/user-placeholder.jpg"
    import NoApproverNotice from "@/components/benefits-leave-reimbursements/NoApproverNotice.vue"
    import EmployeeInfoBlock from "@/components/employee-profile-directory/ui-components/EmployeeInfoBlock.vue"
    import {joinPartsIntoOneLiner} from "@/hooks/general/text-formatters"
    
    const store = useStore()

    const viewableNotice = ref(true)
    
    const employee = computed(() => store.getters.employee)
    const employeeLeaveApprovers = computed(() => store.getters.employeeLeaveApprovers)
    const employeeReimbursementApprovers = computed(() => store.getters.employeeReimbursementApprovers)
    const isEnabledReimbursements = store.getters.isEnabledReimbursements
    const isEnabledLeave = store.getters.isEnabledLeave
    const leaveApproveeList = computed(() => store.getters.leaveApproveeList)
    const reimbursementApproveeList = computed(() => store.getters.reimbursementApproveeList)
    
    const fullName = computed(() => store.getters.userFullName) // not editable
    
    // Original values
    const originalJobTitle = ref(employee.value?.job_title ?? null) // not editable
    const originalEmail = ref(employee.value?.user.email ?? null) // not editable
    const originalIdNumber = ref(employee.value?.id_number ?? null) // not editable
    const originalPreferredName = ref(employee.value?.preferred_name ?? null)
    const originalCellNumber = ref(employee.value?.cell_number ?? null)
    const originalAddress = ref(employee.value?.address ?? null)
    const originalSelfDescription = ref(employee.value?.self_description ?? null)
    const originalEcName = ref(employee.value?.emergency_contact?.name ?? '')
    const originalEcRelationship = ref(employee.value?.emergency_contact?.relationship ?? '')
    const originalEcCellNumber = ref(employee.value?.emergency_contact?.cell_number ?? '')
    const originalUnitNumber = ref(employee.value?.physical_address?.unit_number ?? null)
    const originalComplex = ref(employee.value?.physical_address?.complex ?? null)
    const originalStreetNumber = ref(employee.value?.physical_address?.street_number ?? null)
    const originalStreet = ref(employee.value?.physical_address?.street ?? null)
    const originalSuburb = ref(employee.value?.physical_address?.suburb ?? null)
    const originalCity = ref(employee.value?.physical_address?.city ?? null)
    const originalPostalCode = ref(employee.value?.physical_address?.postal_code ?? null)
    const originalCountry = ref(employee.value?.physical_address?.country ?? null)
    const originalTitle = ref(employee.value?.title ?? null)
    const originalGender = ref(employee.value?.gender ?? null)
    const originalMaritalStatus = ref(employee.value?.marital_status ?? null)
    
    // Current values
    const jobTitle = ref(originalJobTitle.value) // not editable
    const email = ref(originalEmail.value) // not editable
    const idNumber = ref(originalIdNumber.value) // not editable
    const preferredName = ref(originalPreferredName.value)
    const cellNumber = ref(originalCellNumber.value)
    const address = ref(originalAddress.value)
    const selfDescription = ref(originalSelfDescription.value)
    const ecName = ref(originalEcName.value)
    const ecRelationship = ref(originalEcRelationship.value)
    const ecCellNumber = ref(originalEcCellNumber.value)
    const unitNumber = ref(originalUnitNumber.value)
    const complex = ref(originalComplex.value)
    const streetNumber = ref(originalStreetNumber.value)
    const street = ref(originalStreet.value)
    const suburb = ref(originalSuburb.value)
    const city = ref(originalCity.value)
    const postalCode = ref(originalPostalCode.value)
    const country = ref(originalCountry.value)
    const title = ref(originalTitle.value)
    const gender = ref(originalGender.value)
    const maritalStatus = ref(originalMaritalStatus.value)
    
    // Joined one liner values
    const joinedOneLineEmergencyContact = computed(() => joinPartsIntoOneLiner([ecName.value, ecRelationship.value, ecCellNumber.value]))
    const joinedOneLineAddress = computed(() => joinPartsIntoOneLiner([unitNumber.value, complex.value, streetNumber.value, street.value, suburb.value, city.value, postalCode.value, country.value]))
    
    const areChanges = computed(() => {
        return (
            jobTitle.value !== originalJobTitle.value ||
            email.value !== originalEmail.value ||
            idNumber.value !== originalIdNumber.value ||
            preferredName.value !== originalPreferredName.value ||
            cellNumber.value !== originalCellNumber.value ||
            address.value !== originalAddress.value ||
            selfDescription.value !== originalSelfDescription.value ||
            ecName.value !== originalEcName.value ||
            ecRelationship.value !== originalEcRelationship.value ||
            ecCellNumber.value !== originalEcCellNumber.value ||
            unitNumber.value !== originalUnitNumber.value ||
            complex.value !== originalComplex.value ||
            streetNumber.value !== originalStreetNumber.value ||
            street.value !== originalStreet.value ||
            suburb.value !== originalSuburb.value ||
            city.value !== originalCity.value ||
            postalCode.value !== originalPostalCode.value ||
            country.value !== originalCountry.value ||
            title.value !== originalTitle.value ||
            gender.value !== originalGender.value ||
            maritalStatus.value !== originalMaritalStatus.value
        )
    })
    
    const isEditing = ref(false)
    const editHandler = () => isEditing.value = true
    
    const resetValuesToCurrent = () => {
        jobTitle.value = originalJobTitle.value
        email.value = originalEmail.value
        idNumber.value = originalIdNumber.value
        preferredName.value = originalPreferredName.value
        cellNumber.value = originalCellNumber.value
        address.value = originalAddress.value
        selfDescription.value = originalSelfDescription.value
        ecName.value = originalEcName.value
        ecRelationship.value = originalEcRelationship.value
        ecCellNumber.value = originalEcCellNumber.value
        unitNumber.value = originalUnitNumber.value
        complex.value = originalComplex.value
        streetNumber.value = originalStreetNumber.value
        street.value = originalStreet.value
        suburb.value = originalSuburb.value
        city.value = originalCity.value
        postalCode.value = originalPostalCode.value
        country.value = originalCountry.value
        title.value = originalTitle.value
        gender.value = originalGender.value
        maritalStatus.value = originalMaritalStatus.value
    }
    
    const cancelChangesHandler = () => {
        isEditing.value = false
        incompleteAlert.value = false
        resetValuesToCurrent()
    }
    
    const requiredFieldsComplete = computed(() => {
        return isRequiredValidate(cellNumber.value) &&
            isRequiredValidate(ecName.value) &&
            isRequiredValidate(ecRelationship.value) &&
            isRequiredValidate(ecCellNumber.value) &&
            isRequiredValidate(streetNumber.value) &&
            isRequiredValidate(street.value) &&
            isRequiredValidate(suburb.value) &&
            isRequiredValidate(city.value) &&
            isRequiredValidate(postalCode.value) &&
            isRequiredValidate(country.value);
    })
    
    const incompleteAlert = ref(false)
    const checkForm = (event) => {
        incompleteAlert.value = false
        event.target.classList.add("was-validated")
        if (requiredFieldsComplete.value) {
            updatePersonalInfo()
        } else {
            incompleteAlert.value = true
        }
    }
    
    // profile pic
    const showFileUploader = ref(false)
    const isProfilePicChanged = ref(false)
    
    
    const profilePic = ref(employee.value.profile_picture || userPlaceholder) // userPlaceholder is imported image
    if (profilePic.value && profilePic.value.length) {
        profilePic.value = profilePic.value.replace('http://', 'https://')
    }
    
    const uploadedProfilePic = ref(null)
    const fileUploadHandler = (fileList) => {
        isProfilePicChanged.value = true
        uploadedProfilePic.value = null
        if (isValidArray(fileList)) {
            uploadedProfilePic.value = fileList[0]
        }
    }
    
    const fileUploadRef = ref(null) // so I can do methods on it -> https://mdbootstrap.com/docs/vue/plugins/file-upload/#docsTabsAPI
    const cancelAndClearFileUploadHandler = () => {
        fileUploadRef.value?.clear()
        showFileUploader.value = false
        isProfilePicChanged.value = false
    }
    
    
    // the api call to update personal info
    const isUpdatingPersonalInfo = ref(false)
    const updatePersonalInfo = async () => {
        isUpdatingPersonalInfo.value = true
        
        const payload = {
            "preferred_name": preferredName.value,
            "cell_number": cellNumber.value,
            "address": address.value,
            "self_description": selfDescription.value,
            "profile_picture": uploadedProfilePic.value,
            "physical_address": {
                "unit_number": unitNumber.value,
                "complex": complex.value,
                "street_number": streetNumber.value,
                "street": street.value,
                "suburb": suburb.value,
                "city": city.value,
                "postal_code": postalCode.value,
                "country": country.value
            },
            "emergency_contact": {
                "name": ecName.value,
                "relationship": ecRelationship.value,
                "cell_number": ecCellNumber.value
            },
            // "title": title.value,
            // "gender": gender.value,
            // "marital_status": maritalStatus.value
        }
        
        try {
            await store.dispatch('updateEmployeeInfo', payload) // PATCH request
            // can't use the returned employee object because it doesn't have the profile picture full url it only returns: /media/employee/2/bd2467ba-90a5-4dde-aee4-b05b7ef0f5a8.jpg
            // which I could work with but have gone with just loading the employee again rather
            await store.dispatch('loadEmployee', {forceRefresh: true})
            if (employee.value && employee.value.profile_picture && employee.value.profile_picture.length) {
                profilePic.value = employee.value.profile_picture
                profilePic.value = profilePic.value.replace('http://', 'https://')
            }
            
        } catch (error) {
            console.log(error)
            store.commit('setShowApiErrorModal', true)
            store.commit('setApiErrorModalTitle', 'Error updating your profile info')
            store.commit('setApiErrorMessage', error.message)
            isUpdatingPersonalInfo.value = false
            isProfilePicChanged.value = false
            return
        }
        
        showFileUploader.value = false
        isProfilePicChanged.value = false
        isUpdatingPersonalInfo.value = false
        isEditing.value = false
    }
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    // title and buttons
    .page-title-and-buttons-cont {
        flex-wrap: wrap;
        margin-bottom: 24px;
        @media (min-width: 544px) {
            flex-wrap: nowrap;
        }
    }
    .page-title {
        flex: 0 0 100%;
        margin-top: 6px;
        @media (min-width: 544px) {
            flex: 1;
        }
    }
    .edit-save-button-cont {
        flex: 0 0 100%;
        margin-top: 8px;
        @media (min-width: 544px) {
            margin-top: 0;
            flex: 1;
            text-align: right;
        }
        .btn {
            margin-top: 4px;
        }
    }
    .save-white-icon {
        width: 17px;
        position: relative;
        top: -1px;
    }
    
    // image, name, self description
    .top-content-row {
        flex-wrap: wrap;
        @media (min-width: 460px) {
            flex-wrap: nowrap;
        }
    }
    
    .profile-pic-image-col {
        flex: 0 0 100%;
        max-width: 244px;
        order: 2;
        margin-top: 42px;
        @media (min-width: 460px) {
            flex: 1;
            order: 1;
            margin-top: 0;
        }
    }
    .profile-pic-uploader-cont {
        height: 200px;
        box-shadow: none;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .profile-img {
        border-radius: $border-radius;
    }
    :deep(.file-upload-remove-file-btn), :deep(.file-upload-file-name) {
        display: none !important;
    }
    
    
    .name-and-title-col {
        flex: 0 0 100%;
        order: 1;
        @media (min-width: 460px) {
            flex: 1;
            order: 2;
        }
    }
    .name-and-title-card {
        @media (min-width: 460px) {
            min-height: 242px;
        }
    }
    :deep(.file-upload-wrapper) {
        background: rgba(white, .8);
        
        .file-upload-message {
            padding: 10px;
        }
        .fa-cloud-upload-alt:before, .file-upload-default-message {
            color: $gradient-green-text-dark;
        }
        .file-upload-default-message {
            padding: 0 10px;
        }
        .file-upload-preview-img {
            border-radius: 12px;
        }
        .file-upload-preview-details-inner {
            padding-left: 10px;
            padding-right: 10px;
            .file-upload-file-name {
                margin-bottom: 6px;
            }
            .file-upload-preview-message {
                margin-bottom: 2px;
            }
        }
    }
    
    .edit-image-icon {
        border-radius: 3px;
        padding: 4px;
        width: 34px;
        top: 20px;
        left: 20px;
        background: rgba(black, .05);
        z-index: 999;
        text-align: center;
        img {
            width: 100%;
            &.cancel-img {
                max-width: 20px;
            }
        }
    }
    
    .job-title {
        color: $gradient-green-text-dark;
        font-weight: 600;
        font-size: 15px;
        margin-top: 2px;
        white-space: nowrap;
        @include media-breakpoint-up(md) {
            font-size: 16px;
        }
    }
    
    .self-description {
        color: $gradient-green-text-light;
    }
    
    .invalid-feedback-custom {
        font-size: 0.875rem;
        color: $danger-500;
        line-height: 1.3;
    }
</style>