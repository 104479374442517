<template>
    <div v-if="isHrAdmin">
        <loading-full-screen v-model="isSubmittedFinancialInfo" loadingText="Updating your details ..."/>
        
        <modal-confirmation v-model="confirmBankAccountChangesModal">
            <template #title>Confirm bank account change request?</template>
            <template #body>
                <div class="font-size-14">
                    <div><span class="font-weight-500">Bank:</span> {{ bank }}</div>
                    <div><span class="font-weight-500">Account Number:</span> {{ accountNumber }}</div>
                    <div>
                        <span class="font-weight-500">Account Type:</span> {{ optionDisplayText(accountTypeOptions, accountType) }}
                    </div>
                    <div><span class="font-weight-500">Branch Code:</span> {{ branchCode }}</div>
                    <div>
                        <span class="font-weight-500">Account Holder:</span> {{ optionDisplayText(holderRelationshipOptions, holderRelationship) }}
                    </div>
                    <div v-if="holderName"><span class="font-weight-500">Third Party Name:</span> {{ holderName }}</div>
                </div>
            </template>
            <template #button1>
                <button-bento @click="cancelBankAccountChangesHandler" button-color="dark" class="mx-2 flex-grow-1" size="sm">Cancel</button-bento>
            </template>
            <template #button2>
                <button-bento @click="confirmBankAccountChangesHandler" class="mx-2 flex-grow-1" size="sm">Submit changes</button-bento>
            </template>
        </modal-confirmation>
        
        <MDBRow tag="form" class="g-2 needs-validation" novalidate @submit.prevent="checkForm">
            <MDBCol md="12">
                <card-general class="col-12 d-flex align-items-stretch py-0 mt-0">
                    <div
                        @click="financialDetailsCollapse = !financialDetailsCollapse"
                        class="d-flex align-items-center justify-content-between page-title-and-buttons-cont anti-card-general-padding py-4 cursor-pointer"
                        :class="{'top-border-only green': financialDetailsCollapse}"
                    >
                        <div>
                            <page-header class="page-title mb-0">Financial Details</page-header>
                            <div class="fw-500">Bank account and income tax details.</div>
                        </div>
                        <div class="font-size-17">
                            <i class="fas fa-chevron-up" v-if="financialDetailsCollapse"></i> <i class="fas fa-chevron-down" v-else></i>
                        </div>
                    </div>
                    
                    <div v-if="incompleteAlert">
                        <bento-alert color="danger" class="mb-2" static v-model="incompleteAlert" v-if="incompleteAlert">Please complete all required fields below with valid entries.</bento-alert>
                    </div>
                    
                    <MDBCollapse v-model="financialDetailsCollapse" class="mb-2 pb-3">
                        
                        <section-header class="mt-4 pt-1">Bank Details <span v-if="submittedBankAccountChangeRequest && !isEditing">(Current)</span></section-header>
                        <div class="mt-n4">
                            <div class="row g-3">
                                <profile-info-card v-if="!isEditing" title="Bank" :value="bank"/>
                                <div class="col-md-6 card-col" v-show="isEditing">
                                    <MDBSelect
                                        ref="bankOptionsRef"
                                        v-model:options="bankOptions" v-model:selected="bank"
                                        :optionHeight="44"
                                        label="Bank*" :preselect="false"
                                        invalid-feedback="Please select a bank account"
                                        required
                                    />
                                </div>
                                
                                <profile-info-card v-if="!isEditing" title="Acc. Nr" :value="accountNumber"/>
                                <profile-info-card-input-edit
                                    v-else v-model="accountNumber" :input-value="accountNumber" label="Acc. Nr"
                                    invalid-feedback="Please enter a valid account number longer than 4 digits (numbers only)"
                                    :custom-error-message="accountNumberInvalidNotice"
                                    :is-valid-value="isAccountNumberInteger && isAccountNumberLongEnough"
                                    :is-validated-value="true"
                                />
                                
                                <profile-info-card v-if="!isEditing" title="Branch Code" :value="branchCode"/>
                                <profile-info-card-input-edit
                                    v-else v-model="branchCode" :input-value="branchCode" label="Branch Code"
                                    invalid-feedback="Please enter a valid 6 digit branch code (numbers only)"
                                    :custom-error-message="branchCodeInvalidNotice"
                                    :is-valid-value="isBranchCodeInteger && isBranchCodeSixDigits"
                                    :is-validated-value="true"
                                />
                                
                                <profile-info-card v-if="!isEditing" title="Acc. Type" :value="optionDisplayText(accountTypeOptions, accountType)"/>
                                <div class="col-md-6 card-col" v-show="isEditing">
                                    <MDBSelect
                                        ref="accountTypeOptionsRef"
                                        v-model:options="accountTypeOptions" v-model:selected="accountType"
                                        :optionHeight="44"
                                        label="Acc. Type*" :preselect="false"
                                        required
                                    />
                                </div>
                                
                                <profile-info-card v-if="!isEditing" title="Acc. Holder" :value="optionDisplayText(holderRelationshipOptions, holderRelationship)"/>
                                <div class="col-md-6 card-col" v-show="isEditing">
                                    <MDBSelect
                                        ref="accountHolderOptionsRef"
                                        v-model:options="holderRelationshipOptions" v-model:selected="holderRelationship"
                                        :optionHeight="44"
                                        label="Acc. Holder*" :preselect="false"
                                        required
                                    />
                                    <div class="extra-note">The owner of the given bank account</div>
                                </div>
                                
                                <profile-info-card v-if="!isEditing && holderRelationship === thirdPartyAccountValue" title="Third Party Name" :value="holderName"/>
                                <profile-info-card-input-edit
                                    v-if="isEditing && holderRelationship === thirdPartyAccountValue" v-model="holderName" :input-value="holderName" label="Third Party Name" :is-required="true"
                                    extra-note="The name of the third party account holder"
                                />
                            </div>
                        </div>
                        
                        <!--submitted changes-->
                        <div class="row g-3 mt-4" v-if="submittedBankAccountChangeRequest">
                            <div class="d-flex justify-content-between align-items-center">
                                <h4 class="m-0">Submitted Changes</h4>
                            </div>
                            
                            <profile-info-card title="Bank" :value="bankSubmitted" color="pink"/>
                            <profile-info-card title="Acc. Nr" :value="accountNumberSubmitted" color="pink"/>
                            <profile-info-card title="Branch Code" :value="branchCodeSubmitted" color="pink"/>
                            <profile-info-card title="Acc. Type" :value="optionDisplayText(accountTypeOptions, accountTypeSubmitted)" color="pink"/>
                            <profile-info-card title="Acc. Holder" :value="optionDisplayText(holderRelationshipOptions, holderRelationshipSubmitted)" color="pink"/>
                            <profile-info-card title="Third Party Name" :value="holderNameSubmitted" color="pink" v-if="holderRelationshipSubmitted === thirdPartyAccountValue"/>
                            
                            <div class="px-2">
                                <bento-alert color="info" static dismiss v-model="verificationAlert" icon-type="info">Your requested changes are being processed and will be updated once verification is complete.</bento-alert>
                            </div>
                        </div>
                        
                        <section-header class="mt-5">Income Tax Detail</section-header>
                        <div class="mt-n4">
                            <div class="row g-3 mb-3">
                                <profile-info-card title="IRP5" :value="IRP5" :editable="false" :show-edit-icon="false" download-link="https://mybento.net"/>
                                <profile-info-card title="Income Tax Nr" :value="incomeTaxNumber"/>
                            </div>
                            
                            <div class="d-flex align-items-center justify-content-end">
                                <div class="edit-save-button-cont">
                                    <button-bento v-if="isEditing" color="plain-transparent" @click="cancelChangesHandler" size="sm">
                                        Cancel
                                    </button-bento>
                                    <button-bento v-if="!isEditing" color="dark" @click="editHandler" size="sm">
                                        <img src="@/assets/v2/icons/edit/edit-pencil-solid-white.svg" class="me-2"/> <span>Edit Profile</span>
                                    </button-bento>
                                    <button-bento v-if="isEditing && areChanges" color="dark" size="sm" type="submit">
                                        <img src="@/assets/v2/icons/edit/save-white.svg" class="me-2 save-white-icon"/> <span>Save Changes</span>
                                    </button-bento>
                                </div>
                            </div>
                        </div>
                    </MDBCollapse>
                </card-general>
            
            </MDBCol>
        </MDBRow>
    </div>
</template>
<script setup>
    import {MDBRow, MDBCol, MDBSelect, MDBCollapse} from "mdb-vue-ui-kit"
    import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue"
    import PageHeader from "@/components/UI/v2/containers-cards-headers/PageHeader.vue"
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento.vue"
    import {useStore} from "vuex"
    import {ref, computed, watch} from 'vue'
    import SectionHeader from "@/components/UI/v2/containers-cards-headers/SectionHeader.vue"
    import LoadingFullScreen from "@/components/UI/v2/loading/LoadingFullScreen.vue"
    import ProfileInfoCard from "@/components/employee-profile-directory/ui-components/ProfileInfoCard.vue"
    import ProfileInfoCardInputEdit from "@/components/employee-profile-directory/ui-components/ProfileInfoCardInputEdit.vue"
    import BentoAlert from "@/components/UI/v2/alerts/BentoAlert.vue"
    import {isLongerThan, isRequiredValidate} from "@/hooks/general/form-validators"
    import bankNamesSimplePay from "@/data/misc/bank-names-simple-pay"
    import ModalConfirmation from "@/components/summary-and-payslip/modal/base-modals/ModalConfirmation.vue"
    import {isValidArray} from "@/hooks/general/type-and-value-checks"
    import {toTitleCase} from "@/hooks/general/text-formatters";
    const store = useStore()

    const props = defineProps({
        employeeProp: {
            type: Object,
            required: true
        }
    })

    // permissions
    const isHrAdmin = store.getters.isHrAdmin

    const financialDetailsCollapse = ref(false)

    const verificationAlert = ref(true)
    const employee = computed(() => props.employeeProp)
    const isPayrollPayspace = store.getters.isPayrollPayspace
    
    const originalBank = ref(toTitleCase(employee.value.bank_account?.bank) ?? null)
    const originalAccountNumber = ref(employee.value.bank_account?.account_number ?? null)
    const originalAccountType = ref(employee.value.bank_account?.account_type ?? null)
    const originalBranchCode = ref(employee.value.bank_account?.branch_code ?? null)
    const originalHolderRelationship = ref(employee.value.bank_account?.holder_relationship ?? 'employee')
    const originalHolderName = ref(employee.value.bank_account?.holder_name ?? null)
    
    const bank = ref(originalBank.value)
    const accountNumber = ref(originalAccountNumber.value)
    const accountType = ref(originalAccountType.value)
    const branchCode = ref(originalBranchCode.value)
    const holderRelationship = ref(originalHolderRelationship.value)
    const holderName = ref(originalHolderName.value)
    
    const areChanges = computed(() => {
        return (
            bank.value !== originalBank.value ||
            accountNumber.value !== originalAccountNumber.value ||
            accountType.value !== originalAccountType.value ||
            branchCode.value !== originalBranchCode.value ||
            holderRelationship.value !== originalHolderRelationship.value ||
            holderName.value !== originalHolderName.value
        )
    })

    // 1. Bank
    const bankOptionsRef = ref(null)
    const bankOptions = bankNamesSimplePay.map(element => ({
        text: element.bankName,
        value: element.bankName,
        selected: element.bankName === bank.value
    }))

    // 2. Account Number - String - plain text (same for SP and PP)

    // 3. Branch Code - String - plain text (same for SP and PP)

    // 4. Account Type
    const accountTypeOptionsRef = ref(null)

    const accountTypeSavingsValue = isPayrollPayspace ? 'Savings' : 'savings'
    const accountTypeTransmissionValue = isPayrollPayspace ? 'Transmission' : 'transmission'
    const accountTypeBondValue = isPayrollPayspace ? 'Bond Accounts' : 'bond'
    const accountTypeSubscriptionShareValue = isPayrollPayspace ? 'Subscription Share Accounts' : 'subscription share'
    const accountTypeCurrentChequeValue = isPayrollPayspace ? 'Current/Cheques' : 'current'

    const accountTypeOptions = [
        {text: "Savings", value: accountTypeSavingsValue, selected: accountType.value === accountTypeSavingsValue},
        {text: "Transmission", value: accountTypeTransmissionValue, selected: accountType.value === accountTypeTransmissionValue},
        {text: "Bond", value: accountTypeBondValue, selected: accountType.value === accountTypeBondValue},
        {text: "Subscription Share", value: accountTypeSubscriptionShareValue, selected: accountType.value === accountTypeSubscriptionShareValue},
        {text: "Current (Cheque)", value: accountTypeCurrentChequeValue, selected: accountType.value === accountTypeCurrentChequeValue},
    ]

    // 5. Account Holder
    const accountHolderOptionsRef = ref(null)

    const selfHeldAccountValue = isPayrollPayspace ? 'Own' : 'employee'
    const jointAccountValue = isPayrollPayspace ? 'Joint' : 'joint'
    const thirdPartyAccountValue = isPayrollPayspace ? 'Third Party' : 'third party'

    const holderRelationshipOptions = [
        {text: "Self Held Account", secondaryText: "The bank account is held solely by you", value: selfHeldAccountValue, selected: holderRelationship.value === selfHeldAccountValue},
        {text: "Joint Account", secondaryText: "The account is a joint account", value: jointAccountValue, selected: holderRelationship.value === jointAccountValue},
        {text: "Third Party", secondaryText: "Account is held by a third party", value: thirdPartyAccountValue, selected: holderRelationship.value === thirdPartyAccountValue},
    ]

    // 6. Third party holder name - plain text
    
    const optionDisplayText = (optionArray, optionValue) => {
        if (optionValue && optionValue.trim()) {
            const found = optionArray.find(element => element.value === optionValue)
            return found ? found.text : null
        }
        return null
    }
    
    // Income Tax Nr + IRP5
    const incomeTaxNumber = ref(employee.value.income_tax_number)
    const IRP5 = ref('')

    
    // submitted request
    const submittedBankAccountChangeRequestList = ref(null)
    const submittedBankAccountChangeRequest = computed(() => {
        if (isValidArray(submittedBankAccountChangeRequestList.value)) {
            return submittedBankAccountChangeRequestList.value.find(changeRequest => changeRequest.status === 'submitted') || null
        }
        return null
    })
    const bankSubmitted = computed(() => submittedBankAccountChangeRequest.value?.bank ?? null)
    const accountNumberSubmitted = computed(() => submittedBankAccountChangeRequest.value?.account_number ?? null)
    const accountTypeSubmitted = computed(() => submittedBankAccountChangeRequest.value?.account_type ?? null)
    const branchCodeSubmitted = computed(() => submittedBankAccountChangeRequest.value?.branch_code ?? null)
    const holderRelationshipSubmitted = computed(() => submittedBankAccountChangeRequest.value?.holder_relationship ?? null)
    const holderNameSubmitted = computed(() => submittedBankAccountChangeRequest.value?.holder_name ?? null)
    
    const isEditing = ref(false)
    const confirmBankAccountChangesModal = ref(false)
    
    // 1. edit details --------------
    const editHandler = async () => isEditing.value = true
    
    const cancelChangesHandler = () => {
        isEditing.value = false
        incompleteAlert.value = false
        accountNumberInvalidNotice.value = null
        isFormValidated.value = false
        resetValuesToCurrent()
    }
    
    
    // 1.5 Verify fields
    const isFormValidated = ref(false)
    const requiredFieldsComplete = computed(() => {
        if (
            isRequiredValidate(bank.value) &&
            isRequiredValidate(accountNumber.value) &&
            isRequiredValidate(branchCode.value) &&
            isRequiredValidate(accountType.value) &&
            isRequiredValidate(holderRelationship.value) &&
            (holderRelationship.value !== thirdPartyAccountValue || isRequiredValidate(holderName.value))
        ) {
            return true
        }
        return false
    })
    
    // account number validation
    const accountNumberInvalidNotice = ref(null)
    const isAccountNumberInteger = computed(() => Number.isInteger(+accountNumber.value))
    const isAccountNumberLongEnough = computed(() => isLongerThan(accountNumber.value, 4))
    
    // branch code validation
    const branchCodeInvalidNotice = ref(null)
    const isBranchCodeInteger = computed(() => Number.isInteger(+branchCode.value?.trim()) ?? false)
    const isBranchCodeSixDigits = computed(() => branchCode.value?.trim().length === 6 ?? false)
    
    
    // clear the warnings when values are being updated to valid entries
    watch(accountNumber, () => {
        if (isAccountNumberLongEnough.value && isAccountNumberInteger.value) {
            accountNumberInvalidNotice.value = null
        }
    })
    watch(branchCode, () => {
        if (isBranchCodeInteger.value && isBranchCodeSixDigits.value) {
            branchCodeInvalidNotice.value = null
        }
    })
    
    // Watch for changes in employeeProp (changing employee from dropdown)
    watch(
        () => props.employeeProp,
        (newSelectedEmployee) => {
            const loadNewEmployee = async () => {
                isEditing.value = false
                
                incomeTaxNumber.value = newSelectedEmployee.income_tax_number
                
                originalBank.value = newSelectedEmployee.bank_account?.bank ?? null
                bankOptionsRef.value?.setValue(originalBank.value)
                originalAccountNumber.value = newSelectedEmployee.bank_account?.account_number ?? null
                originalAccountType.value = newSelectedEmployee.bank_account?.account_type ?? null
                accountTypeOptionsRef.value?.setValue(originalAccountType.value)
                originalBranchCode.value = newSelectedEmployee.bank_account?.branch_code ?? null
                originalHolderRelationship.value = newSelectedEmployee.bank_account?.holder_relationship ?? 'employee'
                accountHolderOptionsRef.value?.setValue(originalHolderRelationship.value)
                originalHolderName.value = newSelectedEmployee.bank_account?.holder_name ?? null
                
                bank.value = originalBank.value
                accountNumber.value = originalAccountNumber.value
                accountType.value = originalAccountType.value
                branchCode.value = originalBranchCode.value
                holderRelationship.value = originalHolderRelationship.value
                holderName.value = originalHolderName.value
                
                submittedBankAccountChangeRequestList.value = await loadBankAccountChangeRequestList()
            }
            loadNewEmployee()
        },
        {deep: true} // Deep watch to observe nested properties
    )
    
    
    const incompleteAlert = ref(false)
    
    const checkForm = (event) => {
        incompleteAlert.value = false
        isFormValidated.value = true
        event.target.classList.add("was-validated")
        
        if (
            requiredFieldsComplete.value &&
            isAccountNumberInteger.value &&
            isAccountNumberLongEnough.value &&
            isBranchCodeInteger.value &&
            isBranchCodeSixDigits.value
        ) {
            confirmBankAccountChangesModal.value = true
        }
        
        // account number validation
        if (requiredFieldsComplete.value) {
            if (!isAccountNumberInteger.value || !isAccountNumberLongEnough.value) {
                incompleteAlert.value = true
                accountNumberInvalidNotice.value = 'Please enter a valid account number longer than 4 digits (numbers only)'
            }
        } else {
            incompleteAlert.value = true
        }
        
        // branch code validation
        if (requiredFieldsComplete.value) {
            if (!isBranchCodeInteger.value || !isBranchCodeSixDigits.value) {
                incompleteAlert.value = true
                branchCodeInvalidNotice.value = 'Please enter a valid 6 digit branch code (numbers only)'
            }
        } else {
            incompleteAlert.value = true
        }
        
    }
    
    
    // 2. confirm / cancel the request ----------
    
    const confirmBankAccountChangesHandler = () => updateFinancialInfo()
    
    const resetValuesToCurrent = () => {
        bank.value = originalBank.value
        bankOptionsRef.value?.setValue(originalBank.value)
        accountNumber.value = originalAccountNumber.value
        accountType.value = originalAccountType.value
        accountTypeOptionsRef.value?.setValue(originalAccountType.value)
        branchCode.value = originalBranchCode.value
        holderRelationship.value = originalHolderRelationship.value
        accountHolderOptionsRef.value?.setValue(originalHolderRelationship.value)
        holderName.value = originalHolderName.value
    }
    
    const cancelBankAccountChangesHandler = () => {
        isEditing.value = false
        confirmBankAccountChangesModal.value = false
        resetValuesToCurrent()
    }
    
    // 3. submit the request ---------------
    const isSubmittedFinancialInfo = ref(false)
    const updateFinancialInfo = async () => {
        isSubmittedFinancialInfo.value = true
        confirmBankAccountChangesModal.value = false
        isEditing.value = false
        
        const payload = {
            "employee_id": employee.value.id,
            "account_number": accountNumber.value,
            "account_type": accountType.value,
            "branch_code": branchCode.value,
            "bank": bank.value,
            "holder_name": holderName.value,
            "holder_relationship": holderRelationship.value,
            "status": 'submitted'
        }
        
        let changeRequestObjectReturned
        try {
            changeRequestObjectReturned = await store.dispatch('updateFinancialInfo', payload)
            resetValuesToCurrent()
        } catch (error) {
            store.commit('setShowApiErrorModal', true)
            store.commit('setApiErrorModalTitle', 'Error updating your financial info')
            store.commit('setApiErrorMessage', error.message)
            
            isSubmittedFinancialInfo.value = false
            confirmBankAccountChangesModal.value = false
            return
        }
        
        submittedBankAccountChangeRequestList.value = [changeRequestObjectReturned] // need to update the list which is a ref, which will then update the computed requestObject
        
        isSubmittedFinancialInfo.value = false
    }
    
    const loadBankAccountChangeRequestList = async () => {
        let requestList = null
        try {
            requestList = await store.dispatch('loadBankAccountChangeRequestList', {forceRefresh: true, employee_id: employee.value.id})
        } catch (error) {
            store.commit('setShowApiErrorModal', true)
            store.commit('setApiErrorModalTitle', 'Error loading your bank change request details')
            store.commit('setApiErrorMessage', error.message)
        }
        return requestList
    }
    
    const apiCalls = async () => submittedBankAccountChangeRequestList.value = await loadBankAccountChangeRequestList()
    apiCalls()
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    // title and buttons
    .page-title-and-buttons-cont {
        flex-wrap: wrap;
        @media (min-width: 544px) {
            flex-wrap: nowrap;
        }
        border-radius: $border-radius;
        .top-border-only {
            border-radius: $border-radius $border-radius 0 0 !important;
        }
        &:hover {
            @include green;
        }
    }
    .page-title {
        flex: 0 0 100%;
        margin-top: 6px;
        @media (min-width: 544px) {
            flex: 1;
        }
    }
    .edit-save-button-cont {
        flex: 0 0 100%;
        margin-top: 8px;
        @media (min-width: 544px) {
            margin-top: 0;
            flex: 1;
            text-align: right;
        }
        .btn {
            margin-top: 4px;
        }
    }
    .save-white-icon {
        width: 17px;
        position: relative;
        top: -1px;
    }
    
    // image, name, self description
    .top-content-row {
        flex-wrap: wrap;
        @media (min-width: 460px) {
            flex-wrap: nowrap;
        }
    }
    
    .profile-pic-image-col {
        flex: 0 0 100%;
        max-width: 244px;
        order: 2;
        margin-top: 42px;
        @media (min-width: 460px) {
            flex: 1;
            order: 1;
            margin-top: 0;
        }
    }
    .profile-pic-uploader-cont {
        height: 200px;
        box-shadow: none;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .profile-img {
        border-radius: $border-radius;
    }
    :deep(.file-upload-remove-file-btn), :deep(.file-upload-file-name) {
        display: none !important;
    }
    
    
    .name-and-title-col {
        flex: 0 0 100%;
        order: 1;
        @media (min-width: 460px) {
            flex: 1;
            order: 2;
        }
    }
    .name-and-title-card {
        @media (min-width: 460px) {
            min-height: 242px;
        }
    }
    :deep(.file-upload-wrapper) {
        background: rgba(white, .8);
        
        .file-upload-message {
            padding: 10px;
        }
        .fa-cloud-upload-alt:before, .file-upload-default-message {
            color: $gradient-green-text-dark;
        }
        .file-upload-default-message {
            padding: 0 10px;
        }
        .file-upload-preview-img {
            border-radius: 12px;
        }
        .file-upload-preview-details-inner {
            padding-left: 10px;
            padding-right: 10px;
            .file-upload-file-name {
                margin-bottom: 6px;
            }
            .file-upload-preview-message {
                margin-bottom: 2px;
            }
        }
    }
    
    .edit-image-icon {
        border-radius: 3px;
        padding: 4px;
        width: 34px;
        top: 20px;
        left: 20px;
        background: rgba(black, .05);
        z-index: 999;
        text-align: center;
        img {
            width: 100%;
            &.cancel-img {
                max-width: 20px;
            }
        }
    }
    
    .job-title {
        color: $gradient-green-text-dark;
        font-weight: 600;
        font-size: 15px;
        margin-top: 2px;
        white-space: nowrap;
        @include media-breakpoint-up(md) {
            font-size: 16px;
        }
    }
    
    .self-description {
        color: $gradient-green-text-light;
    }
    
    .invalid-feedback-custom {
        font-size: 0.875rem;
        color: $danger-500;
        line-height: 1.3;
    }
    
    // MDBCollapse
    :deep(form.needs-validation.was-validated) {
        .collapse {
            height: auto !important;
        }
    }
    
    // for MDB form elements directly used in here (Bank Account)
    :deep(.form-outline) {
        .form-control {
            min-height: 52px;
            color: $gradient-green-text-dark;
        }
        label.form-label {
            font-size: 13px;
        }
    }
    
    .extra-note {
        width: 100%;
        font-size: 11px;
        color: $gray-main;
    }
</style>