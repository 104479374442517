<template>
    <div>
        <loading-full-screen v-model="isUpdatingRequest" loadingText="Updating leave request ..."/>

        <modal-basic v-model="showOverlappingRequestModal">
            <template #title>
                Overlapping leave request
            </template>
            <template #body>
                <div class="font-weight-400">
                    There is already an approved leave request which overlaps with the request you are trying to approve. Cancel the existing approved leave request in order to approve the new request.<br>
                </div>
                <div class="mt-4 mb-2">
                    <div class="font-weight-500">Existing approved request:</div>
                    <div>
                        <span class="font-weight-400">Leave Type: </span><span class="text-secondary-700">{{ overlappingRequest.leave_type_name }}</span>
                    </div>
                    <div>
                        <span class="font-weight-400">Request dates: </span><span class="text-secondary-700">{{ dateRangeFormatter(overlappingRequest.start_date, overlappingRequest.end_date) }} <span v-if="overlappingRequest.hours == 4">(Half Day)</span></span>
                    </div>
                    <div>
                        <span class="font-weight-400">Request Status: </span><span class="text-secondary-700">{{ toTitleCase(overlappingRequest.state) }}</span>
                    </div>
                </div>
                <div class="mt-4 font-weight-400">
                    Payroll restricts having two approved overlapping leave requests and will return an error for the new leave request.
                </div>
            </template>
            <template #button1>
                <button-bento @click="showOverlappingRequestModal = false" size="lg" button-color="secondary-light" class="mx-2 flex-grow-1 px-1">Back to requests</button-bento>
            </template>
            <template #button2>
                <button-bento @click="updateLeaveRequestStateHandler(processingRequestPayload, false)" class="mx-2 flex-grow-1 px-1" size="lg">Try submit anyway</button-bento>
            </template>
        </modal-basic>

        <card-general id="requests-table">
            <h3>Leave Requests</h3>

            <div v-if="apiErrorMessage || successMessage" id="api-message-response-container">
                <div v-if="apiErrorMessage">
                    <bento-alert class="mt-4" static color="danger" dismiss v-model="showApiErrorMessage">
                        <span v-html="apiErrorMessage"></span>
                    </bento-alert>
                </div>
                <div v-if="successMessage">
                    <bento-alert class="mt-4" icon-type="success-tick" static dismiss v-model="showSuccessMessage">
                        {{ successMessage }}
                    </bento-alert>
                </div>
            </div>

            <div class="mt-4" v-if="false">
                <h6>These are in parent</h6>
                filteredEmployeeList: {{ filteredEmployeeListString }}<br> filteredStatusList: {{ filteredStatusListString }}<br> areSelectionsMade: {{ areSelectionsMade }}
            </div>

            <div class="d-flex justify-content-between mt-4 align-items-center flex-wrap">
                <div class="flex-grow-1 d-flex align-items-center flex-wrap">
                    <data-table-filter
                        filter-name="Employee filter" filter-identifier="employee-filter" placeholder-text="Select employee ..."
                        :filter-options="filterEmployeeOptions" @option-selected="optionSelectedHandler" class="ms-0 mx-1"
                        :selected-options-string="filteredEmployeeListString"
                        options-selected-label="employees selected"
                    />

                    <data-table-filter
                        filter-name="Request Status" filter-identifier="status-filter" placeholder-text="Select leave request status ..."
                        :filter-options="filterStatusOptions" @option-selected="optionSelectedHandler" class="mx-1"
                        :selected-options-string="filteredStatusListString"
                        options-selected-label="statuses selected"
                    />

                    <data-table-filter
                        filter-name="Leave Type" filter-identifier="leave-type-filter" placeholder-text="Select leave type ..."
                        :filter-options="filterTypeOptions" @option-selected="optionSelectedHandler" class="mx-1"
                        :selected-options-string="filteredTypeListString"
                        options-selected-label="leave types selected"
                    />
                </div>

                <button-bento color="primary" @click="clearFilters" :disabled="!areSelectionsMade" class="filter-button mb-3">
                    <span v-if="areSelectionsMade">Clear Filters</span><span v-else>No filters applied</span>
                </button-bento>
            </div>

            <MDBDatatable
                :dataset="tableDataset"
                hover
                fixedHeader
                striped
                :entries="25"
                id="leave-requests-table" class="my-3"
                @render="renderActions"
                sm
                noFoundMessage="No matching leave requests ..."
            />

            <div class="gradient-green-text-dark fw-500 font-size-16" v-if="isFutureLeaveBalanceEnabled">
                <router-link :to="{name:'my-team-leave-balance-lookup'}">
                    <span class="bento-underline-dark-green-hover">Get future leave balances of your team here</span> ->
                </router-link>
            </div>
        </card-general>

        <modal-basic v-model="leaveNoteModal">
            <template #title>Leave Request Note</template>
            <template #body>
                <p class="fst-italic">"{{ leaveNoteModalText }}"</p>
                <p class="font-weight-500">{{ leaveNoteAuthor }}</p>
            </template>
        </modal-basic>
    </div>
</template>

<script setup>
import {isValidArray} from "@/hooks/general/type-and-value-checks"
import {dateRangeFormatter, formatDate} from "@/hooks/general/date-helpers"
import {toTitleCase} from "@/hooks/general/text-formatters"
import {MDBDatatable} from 'mdb-vue-ui-kit'
import {computed, ref, nextTick} from "vue"
import {useStore} from "vuex"
import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral"
import ButtonBento from "@/components/UI/v2/buttons/ButtonBento"
import LoadingFullScreen from "@/components/UI/v2/loading/LoadingFullScreen"
import BentoAlert from "@/components/UI/v2/alerts/BentoAlert"
import ModalBasic from "@/components/UI/v2/modals/ModalBasic"
import DataTableFilter from "@/components/manager/shared-ui-components/DataTableFilter.vue";
import useGetLeaveTypesFromApproveeList from "@/hooks/leave/get-leave-types-from-approvee-list";

const store = useStore()

const isFutureLeaveBalanceEnabled = computed(() => store.getters.isFutureLeaveBalanceEnabled)

const isUpdatingRequest = ref(false)
const apiErrorMessage = ref(null)
const showApiErrorMessage = ref(true)
const successMessage = ref(null)
const showSuccessMessage = ref(true)

const renderActions = () => {
    setActionEventListeners()
    setDownloadListener()
    setNoteReadListener()
    setColspan()
}

// Data prep flow: 1.leaveApproveeList --> 2.rawData --> 3.data --> inserted in tableDataset as data.rows
const leaveApproveeList = computed(() => store.getters.leaveApproveeList)

// ### options for filter dropdowns ( 1. employee, 2. status, 3. leave type)

// 1. Employee filter
const filterEmployeeOptions = [];
for (const approvee of leaveApproveeList.value) {
    const option = {text: approvee.name, value: approvee.id}
    filterEmployeeOptions.push(option)
}

// 2. Status filter
// value needs to be like this for filtering purposes as that is what is in the table
const filterStatusOptions = [
    {text: 'Submitted', value: '<button class="state-badge state-badge-submitted">Submitted</button>'},
    {text: 'Approved', value: '<button class="state-badge state-badge-approved">Approved</button>'},
    {text: 'Rejected', value: '<button class="state-badge state-badge-rejected">Rejected</button>'},
    {text: 'Cancelled', value: '<button class="state-badge state-badge-cancelled">Cancelled</button>'},
    {text: 'Error', value: '<button class="state-badge state-badge-error">Error</button>'}
]

// 3. Type of leave filter (Annual, Sick, etc.)
const consolidatedLeaveTypes = useGetLeaveTypesFromApproveeList(leaveApproveeList.value)

const filterTypeOptions = []
for (const balanceObj of consolidatedLeaveTypes) {
    const option = {
        text: balanceObj.description ? balanceObj.description : balanceObj.name,
        value: balanceObj.id
    }
    filterTypeOptions.push(option)
}

// enrich the regular leave object with employee_name, balance, and date_range
const rawData = computed(() => {
    let list = []
    for (const approvee of leaveApproveeList.value) {
        const leaveRequestsEnriched = approvee.leave_requests.map(request => {
            const balanceObj = approvee.leave_balances.find(balanceObj => balanceObj.id == request.leave_type)
            let balance = null
            if (balanceObj) {
                balance = balanceObj.balance
            }
            const dateRange = dateRangeFormatter(request.start_date, request.end_date)
            return {
                ...request,
                employee_name: approvee.name,
                balance,
                date_range: dateRange
            }
        })
        list.push(...leaveRequestsEnriched)
    }
    return list
})

const filteredEmployeeList = ref(null)
const filteredEmployeeListString = computed(() => isValidArray(filteredEmployeeList.value) ? filteredEmployeeList.value.join(',') : null)

const filteredStatusList = ref(null)
const filteredStatusListString = computed(() => isValidArray(filteredStatusList.value) ? filteredStatusList.value.join(',') : null)

const filteredTypeList = ref(null)
const filteredTypeListString = computed(() => isValidArray(filteredTypeList.value) ? filteredTypeList.value.join(',') : null)

const areSelectionsMade = computed(() => !!filteredStatusList.value || !!filteredEmployeeList.value || !!filteredTypeList.value)

const clearFilters = () => {
    filteredEmployeeList.value = null
    filteredStatusList.value = null
    filteredTypeList.value = null
}

const optionSelectedHandler = (selectedOptionsList, filterIdentifier) => {
    if (filterIdentifier === 'employee-filter') {
        filteredEmployeeList.value = selectedOptionsList.value
    }
    if (filterIdentifier === 'status-filter') {
        filteredStatusList.value = selectedOptionsList.value
    }
    if (filterIdentifier === 'leave-type-filter') {
        filteredTypeList.value = selectedOptionsList.value
    }
}

// 1. inject extra content into each request row
// 2. apply filters - updates the computed property
const data = computed(() => {
    let datasetRows = rawData.value.map(requestRow => {
        const downloadIconSrc = require('@/assets/v2/icons/request-attachment.svg')
        const noteIconSrc = require('@/assets/v2/icons/request-note.svg')

        // Need to escape inverted commas in notes
        const escapedNotes = requestRow.notes?.replace(/"/g, '&quot;')

        // console.log(requestRow)

        return {
            ...requestRow,
            created_at: formatDate(requestRow.created_at, true),
            start_date: formatDate(requestRow.start_date, true),
            end_date: formatDate(requestRow.end_date, true),
            updated_at: formatDate(requestRow.updated_at, true),
            approver: requestRow.approver?.name,
            state: `<button class="state-badge state-badge-${requestRow.state}">${requestRow.state.charAt(0).toUpperCase() + requestRow.state.slice(1)}</button>`,
            actions: `
                            <button class="action-badge approve-badge approve-badge-for-request ${requestRow.state}" data-download-url="${requestRow.attachment}" data-request-id="${requestRow.id}" data-employee-id="${requestRow.employee.id
            }" data-employee-name="${requestRow.employee_name}">Approve</button>
                            <button class="action-badge reject-badge reject-badge-for-request ${requestRow.state}" data-request-id="${requestRow.id}" data-employee-id="${requestRow.employee.id}" data-employee-name="${requestRow.employee_name}">Reject</button>
                            <button class="action-badge cancel-badge cancel-badge-for-request ${requestRow.state}" data-request-id="${requestRow.id}" data-employee-id="${requestRow.employee.id}" data-employee-name="${requestRow.employee_name}">Cancel</button>`,
            docs: `
                            <img title="View note" src="${noteIconSrc}" class="request-icon view-note-icon cursor-pointer me-1 d-inline-block" data-request-note="${escapedNotes}" data-employee-name="${requestRow.employee_name}">
                            <img title="Download attachment" src="${downloadIconSrc}" class="request-icon download-attachment-icon d-inline-block cursor-pointer" data-attachment-url="${requestRow.attachment}">`
        }
    })
    // reorder on the basis of the latest request
    datasetRows.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))

    // The employee filter
    if (isValidArray(filteredEmployeeList.value)) {
        const numbersInArray = filteredEmployeeList.value.map(stringElement => Number(stringElement))
        datasetRows = datasetRows.filter(row => numbersInArray.includes(row.employee.id))
    }
    // The status filter
    if (isValidArray(filteredStatusList.value)) {
        datasetRows = datasetRows.filter(row => filteredStatusList.value.includes(row.state))
    }
    // The leave type filter
    if (isValidArray(filteredTypeList.value)) {
        datasetRows = datasetRows.filter(row => filteredTypeList.value.includes(row.leave_type.toString()))
    }
    return datasetRows
})

const tableDataset = computed(() => {
    return {
        columns: [
            {label: "ID", field: "id", width: '62'},
            {label: "Status", field: "state"},
            {label: "Employee", field: "employee_name", width: '140'},
            {label: "Leave Type", field: "leave_type_name", width: '128'},
            {label: "Start date", field: "start_date", width: '100'},
            {label: "End date", field: "end_date", width: '100'},
            {label: "Days", field: "days", width: '66'},
            // {label: "Dates", field: "date_range", sort: false},
            {label: "Actions", field: "actions", sort: false},
            {label: "Docs", field: "docs", sort: false, width: '68'},
            {label: "Submitted on", field: "created_at"},
            {label: "Balance", field: "balance"},
            {label: "Approver", field: "approver", sort: false, width: 120},
            {label: "Date Updated", field: "updated_at"},
        ],
        rows: data.value
    }
})

const setActionListener = (actionType) => {
    let className
    let updatedState
    switch (actionType) {
        case 'cancel':
            className = 'cancel-badge-for-request'
            updatedState = 'cancelled'
            break
        case 'approve':
            className = 'approve-badge-for-request'
            updatedState = 'approved'
            break
        case 'reject':
            className = 'reject-badge-for-request'
            updatedState = 'rejected'
            break
        default:
            return
    }

    Array.from(document.getElementsByClassName(className)).forEach(actionBtn => {
        if (actionBtn.getAttribute("click-listener") !== "true") {
            actionBtn.addEventListener("click", () => {
                const requestID = actionBtn.attributes["data-request-id"].value
                const employeeID = actionBtn.attributes["data-employee-id"].value
                const employeeName = actionBtn.attributes["data-employee-name"].value
                updateLeaveRequestStateHandler({
                    requestID,
                    updatedState,
                    employeeID,
                    employeeName,
                })
            });
            actionBtn.setAttribute("click-listener", "true")
        }
    });
}
// Listeners are set and reset on component render / rerender (which are triggered with actions like sort)
const setActionEventListeners = () => {
    setActionListener('cancel')
    setActionListener('approve')
    setActionListener('reject')
};

const setDownloadListener = () => {
    Array.from(document.getElementsByClassName('download-attachment-icon')).forEach(downloadBtn => {
        const dataDownloadUrl = downloadBtn.attributes["data-attachment-url"].value

        if (dataDownloadUrl == 'null') {
            downloadBtn.classList.add("d-none")
        }

        if (downloadBtn.getAttribute("click-listener") !== "true" && dataDownloadUrl) {
            downloadBtn.addEventListener("click", () => {
                const anchor = document.createElement("a")
                anchor.href = dataDownloadUrl
                anchor.target = '_blank'
                document.body.appendChild(anchor)
                anchor.click()
                document.body.removeChild(anchor)
            });
            downloadBtn.setAttribute("click-listener", "true")
        }
    });
}

// SET NOTE VIEWER
const leaveNoteModal = ref(false)
const leaveNoteModalText = ref(null)
const leaveNoteAuthor = ref(null)
const setNoteReadListener = () => {
    Array.from(document.getElementsByClassName('view-note-icon')).forEach(noteIcon => {
        const requestNotes = noteIcon.attributes["data-request-note"].value
        const employeeName = noteIcon.attributes["data-employee-name"].value
        if (requestNotes == 'null' || !requestNotes || requestNotes.trim() == '') {
            noteIcon.classList.add("d-none")
        }

        if (noteIcon.getAttribute("click-listener") !== "true" && requestNotes) {
            noteIcon.addEventListener("click", () => {
                leaveNoteModal.value = true
                leaveNoteModalText.value = requestNotes
                leaveNoteAuthor.value = employeeName
            });
            noteIcon.setAttribute("click-listener", "true")
        }
    })
}

const scrollToRequestsTable = () => {
    const requestsTable = document.getElementById('requests-table')
    if (requestsTable) {
        const rect = requestsTable.getBoundingClientRect()
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop
        const tablePosition = rect.top + scrollTop - 100
        window.scrollTo({
            top: tablePosition,
            behavior: 'smooth'
        })
    }
}

const showOverlappingRequestModal = ref(false)
const overlappingRequest = ref(null)
const processingRequestPayload = ref(null)
const updateLeaveRequestStateHandler = async (payload, checkRequestOverlap = true) => {
    showOverlappingRequestModal.value = false
    apiErrorMessage.value = null
    successMessage.value = null
    isUpdatingRequest.value = true
    processingRequestPayload.value = payload

    // only check on requests being approved
    if (payload.updatedState === 'approved' && checkRequestOverlap) {

        // 1. get all approved leave requests for this employee
        const approvedLeaveRequestsForEmployee = rawData.value.filter(leaveRequest => (leaveRequest.employee == payload.employeeID) && (leaveRequest.state === 'approved'))

        // 2. compare this leave request with existing list of leave requests
        const requestBeingApproved = rawData.value.find(leaveRequest => leaveRequest.id == payload.requestID)

        // test for overlapping request
        if (isValidArray(approvedLeaveRequestsForEmployee)) {
            const newRequestStartDate = new Date(requestBeingApproved.start_date).getTime()
            const newRequestEndDate = new Date(requestBeingApproved.end_date).getTime()
            for (const requestObj of approvedLeaveRequestsForEmployee) {
                const requestStartDate = new Date(requestObj.start_date).getTime()
                const requestEndDate = new Date(requestObj.end_date).getTime()

                if (
                    ((newRequestStartDate >= requestStartDate) && (newRequestStartDate <= requestEndDate)) ||
                    ((newRequestEndDate >= requestStartDate) && (newRequestEndDate <= requestEndDate))
                ) {
                    showOverlappingRequestModal.value = true
                    overlappingRequest.value = requestObj
                    isUpdatingRequest.value = false
                    return
                }
            }
        }
    }

    let response
    try {
        response = await store.dispatch('updateLeaveRequestState', payload)
    } catch (error) {
        apiErrorMessage.value = error.message
        isUpdatingRequest.value = false
    }
    isUpdatingRequest.value = false

    if (response && response.response.status === 200) {
        const employeeName = payload.employeeName
        let state = response.responseData.state
        if (state === 'error') {
            apiErrorMessage.value = `${response.responseData.error_message}.<br>
The leave request has been archived with an error status. Please <a class="font-weight-700 text-decoration-underline" href="mailto:hello@mybento.net">contact us</a> if you require assistance.`
        } else {
            successMessage.value = `Leave request for ${employeeName} was successfully ${state}.`
        }
    }

    // After updating the leave request and showing any success or error messages
    scrollToRequestsTable()
}


// full width td - no matching leave requests
const setColspan = async () => {
    await nextTick() // Wait for the next DOM update cycle
    const table = document.querySelector('#leave-requests-table table')
    if (!table) return // Exit if the table is not found

    const tdTags = table.getElementsByTagName('td');
    let searchText = 'No matching leave requests ...'
    let found
    // set colspan
    for (let i = 0; i < tdTags.length; i++) {
        if (tdTags[i].textContent == searchText) {
            found = tdTags[i]
        }
        tdTags[i].setAttribute('title', tdTags[i].innerText)
    }
    const numberOfCols = table.getElementsByTagName('th').length
    if (found) {
        found.colSpan = numberOfCols
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/global-scss/variables-and-mixins.scss';

.filter-button {
    min-width: 166px;
}

:deep(.datatable-pagination) {
    border-top: none;
    margin-top: 15px;
}

// adapted from: https://stackoverflow.com/questions/4094126/how-to-add-border-radius-on-table-row
:deep(#leave-requests-table) {

    table {
        border-collapse: separate !important;
        border-spacing: 0;

        td, th {
            border: 1px none #C6C6C6;
        }

        // the borders
        th {
            border-top-style: solid;
        }
        tr:last-child td {
            border-bottom-style: solid;
        }
        th:first-child {
            border-left-style: solid;
        }
        th:last-child {
            border-right-style: solid;
        }
        tr td:first-child {
            border-left-style: solid;
        }
        tr td:last-child {
            border-right-style: solid;
        }

        tr:last-child {
            height: 45px;
        }

        // the radiuses
        th:first-child {
            border-top-left-radius: 6px;
        }
        th:last-child {
            border-top-right-radius: 6px;
        }
        tr:last-child td:first-child {
            border-bottom-left-radius: 6px;
        }
        tr:last-child td:last-child {
            border-bottom-right-radius: 6px;
        }
    }

    thead {
        th {
            padding: 14px 18px 14px 12px;
            background-color: $gray-50;
            i {
                left: 2px;
            }
        }
    }
    tbody {
        td {
            padding: 10px 12px;
        }
    }
    &.datatable.datatable-striped tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, .01);
    }
    button.action-badge,
    button.state-badge {
        border: none;
        box-shadow: none;
        font-weight: 500;
        font-size: 11px;
        border-radius: 6px;
        color: white;
        display: inline-block;
        transition: all .2s ease-in-out;
        width: 57px;

        // actions
        &.cancelled, &.error, &.rejected {
            display: none;
        }
        &.approve-badge {
            &.approved {
                display: none;
            }
            background: $primary;
            margin-right: 4px;
            &:hover {
                background: darken($primary, 8%);
            }
        }
        &.reject-badge {
            &.approved {
                display: none;
            }
            background: $secondary;
            &:hover {
                background: darken($secondary, 10%);
            }
        }
        &.cancel-badge {
            &.submitted {
                display: none;
            }
            background: $gray-main;
            &:hover {
                background: darken($gray-main, 8%);
            }
        }

        // state
        &.state-badge-submitted {
            background: black;
        }
        &.state-badge-approved {
            background: $primary;
        }
        &.state-badge-rejected {
            background-color: #CAA5A5;
        }
        &.state-badge-cancelled {
            background-color: #B4B4B4;
        }
        &.state-badge-error {
            background-color: #D5474C;
        }
    }

    button.state-badge {
        width: 68px;
    }

    .request-icon {
        width: 14px;
    }
}
</style>